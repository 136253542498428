var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('RowWrap', {
    class: [{
      'is-empty': _vm.mOperationRecords.length === 0
    }],
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "parentHover": false,
            "items": {
              row: _vm.header
            },
            "index": -1
          },
          on: {
            "onClickColumn": function onClickColumn(e) {
              return _vm.handleHeaderClick(e);
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.mOperationRecords, function (operationRecord, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "isDropdown": "",
        "parentHover": true,
        "items": operationRecord,
        "index": i
      }
    });
  }), _vm.mOperationRecords.length === 0 ? _c('EmptyState') : _vm._e()], 2), _vm.mOperationRecords.length >= 30 && _vm.mOperationRecords.length < _vm.operationRecords.length ? _c('Button', {
    staticClass: "Button--LoadMore",
    on: {
      "onClick": _vm.handleLoadMore
    }
  }, [_vm._v(" Mehr Laden ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }