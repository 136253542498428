<template>
    <div>
        <RowWrap :class="[{ 'is-empty': mOperationRecords.length === 0 }]">
            <template #header>
                <Row
                    :parentHover="false"
                    :items="{ row: header }"
                    :key="-1"
                    :index="-1"
                    @onClickColumn="e => handleHeaderClick(e)"
                    class="Row-Header"
                />
            </template>
            <Row
                isDropdown
                v-for="(operationRecord, i) in mOperationRecords"
                :parentHover="true"
                :items="operationRecord"
                :key="i"
                :index="i"
            >
            </Row>
            <EmptyState v-if="mOperationRecords.length === 0" />
        </RowWrap>
        <Button
            class="Button--LoadMore"
            v-if="
                mOperationRecords.length >= 30 && mOperationRecords.length < operationRecords.length
            "
            @onClick="handleLoadMore"
        >
            Mehr Laden
        </Button>
    </div>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import Button from '@/components/widgets/Button';
import { format } from 'date-fns';
import { priceToEuroString, euro, distanceToString } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'ItemTable',
    components: {
        RowWrap,
        Row,
        Button,
        EmptyState,
    },
    props: {
        operationRecords: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
            cursor: 80,
            hasOnlyOneCarInRecords: false,
            sortState: {
                column: 'Ende',
                ascending: true,
            },
        };
    },

    computed: {
        header() {
            return [
                { name: 'Fahrer', value: null },
                { name: 'Kennz.', value: null },
                { name: 'Beginn', value: null },
                { name: 'Ende', value: null },
                { name: 'Dauer', value: null },
                { name: 'KM-Abfahrt', value: null },
                { name: 'KM-Ankunft', value: null },
                { name: 'Gesamt KM', value: null },
                { name: 'Besetzt KM', value: null },
                { name: 'Touren', value: null },
                { name: 'Eur/KM', value: null },
                { name: 'Umsatz (€)', value: null },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mOperationRecords() {
            const records = this.operationRecords?.length
                ? this.sortOperationRecords([
                      ...this.formatOperationRecords(this.operationRecords),
                  ]).reverse()
                : [];
            return this.hasOnlyOneCarInRecords ? records : records.slice(0, this.cursor);
        },
    },
    methods: {
        handleHeaderClick(column) {
            this.$toasted.show('Sortierung ist hier fix auf Ende', { type: 'info' });
            // const columnName = column.name
            //     .replace('▲', '')
            //     .replace('▼', '')
            //     .trim();

            // if (this.sortState.column === columnName) {
            //     this.sortState.ascending = !this.sortState.ascending;
            // } else {
            //     this.sortState.column = columnName;
            //     this.sortState.ascending = true;
            // }
        },
        sortOperationRecords(arr) {
            const { column, ascending } = this.sortState;
            return arr.sort((a, b) => {
                const aEntry = a.row.find(item => {
                    return item.name == column;
                });
                const bEntry = b.row.find(item => {
                    return item.name == column;
                });
                const aVal = aEntry?.rawValue;
                const bVal = bEntry?.rawValue;

                if (ascending) {
                    return aVal > bVal ? 1 : -1;
                }
                return aVal < bVal ? 1 : -1;
            });
        },
        handleLoadMore() {
            this.cursor += 80;
        },
        formatOperationRecords(operationRecords) {
            let formattedRecords = [];
            // check if all records are for the same car and the sequenceNumber is increasing

            this.hasOnlyOneCarInRecords = operationRecords.every((entry, index, array) => {
                if (index === 0) return true;
                const prevEntry = array[index - 1];
                return (
                    prevEntry.licenseNumber === entry.licenseNumber &&
                    prevEntry.sequenceNumber === entry.sequenceNumber + 1
                );
            });
            operationRecords.forEach((operationRecord, index) => {
                formattedRecords.push(this.createOperationRecordRow(operationRecord));

                if (index > 0 && this.hasOnlyOneCarInRecords) {
                    const previousOperationRecord = operationRecords[index - 1];
                    const gap = previousOperationRecord.mileageStart - operationRecord.mileageEnd;

                    if (gap > 0) {
                        const indexOfOperationRecord = formattedRecords.findIndex(
                            f => f.sequenceNumber === operationRecord.sequenceNumber,
                        );
                        formattedRecords.splice(indexOfOperationRecord, 0, this.createGapRow(gap));
                    }
                }
            });

            const sumOfAllGaps = formattedRecords.reduce((acc, curr) => {
                return acc + (curr.raw || 0);
            }, 0);

            this.$emit('onSumOfAllGaps', sumOfAllGaps);
            return formattedRecords;
        },
        createOperationRecordRow(operationRecord) {
            return {
                ...operationRecord,
                row: [
                    {
                        name: 'Fahrer',
                        value: operationRecord.driver?.name || '',
                        rawValue: operationRecord.driver?.name,
                    },
                    {
                        name: 'Kennz.',
                        value: operationRecord.licenseNumber,
                        rawValue: operationRecord.licenseNumber,
                    },
                    {
                        name: 'Beginn',
                        value: format(new Date(operationRecord.startAt), 'dd.MM.yyyy HH:mm'),
                        rawValue: new Date(operationRecord.startAt),
                    },
                    {
                        name: 'Ende',
                        value: format(new Date(operationRecord.endAt), 'dd.MM.yyyy HH:mm'),
                        rawValue: new Date(operationRecord.endAt),
                    },
                    {
                        name: 'Dauer',
                        value: `${Math.floor(
                            (new Date(operationRecord.endAt).getTime() -
                                new Date(operationRecord.startAt).getTime()) /
                                1000 /
                                60 /
                                60,
                        )}h ${Math.floor(
                            ((new Date(operationRecord.endAt).getTime() -
                                new Date(operationRecord.startAt).getTime()) /
                                1000 /
                                60) %
                                60,
                        )}m`,
                        rawValue:
                            (new Date(operationRecord.endAt).getTime() -
                                new Date(operationRecord.startAt).getTime()) /
                            1000 /
                            60 /
                            60,
                    },
                    {
                        name: 'KM-Abfahrt',
                        value: distanceToString(operationRecord.mileageStart),
                        rawValue: operationRecord.mileageStart,
                    },
                    {
                        name: 'KM-Ankunft',
                        value: distanceToString(operationRecord.mileageEnd),
                        rawValue: operationRecord.mileageEnd,
                    },
                    {
                        name: 'Gesamt KM',
                        value: distanceToString(operationRecord.totalDistance),
                        rawValue: operationRecord.totalDistance,
                    },
                    {
                        name: 'Besetzt KM',
                        value: distanceToString(operationRecord.hiredDistance),
                        rawValue: operationRecord.hiredDistance,
                    },
                    {
                        name: 'Touren',
                        value: operationRecord.tripsCount,
                        rawValue: operationRecord.tripsCount,
                    },
                    {
                        name: 'Eur/KM',
                        value: priceToEuroString(
                            operationRecord.finalTotalAmount /
                                Math.max(operationRecord.totalDistance / 1000, 1),
                        ),
                        rawValue:
                            operationRecord.finalTotalAmount /
                            Math.max(operationRecord.totalDistance / 1000, 1),
                    },
                    {
                        name: 'Umsatz (€)',
                        value: priceToEuroString(operationRecord.finalTotalAmount),
                        rawValue: operationRecord.finalTotalAmount,
                    },
                ],
                children: [
                    {
                        row: [
                            // { name: 'Fahrer', value: null },
                            // { name: 'Kennz.', value: null },
                            { name: 'Beginn', value: null },
                            { name: 'Ende', value: null },
                            { name: 'Gesamt KM', value: null },
                            { name: 'Besetzt KM', value: null },
                            { name: 'Eur/KM', value: null },
                            { name: 'Umsatz (€)', value: null },
                        ],
                    },
                    ...this.formatTrips(operationRecord.trips),
                ],
                data: operationRecord,
            };
        },
        createGapRow(gap) {
            return {
                props: {
                    style: {
                        backgroundColor: 'transparent',
                        border: '1px solid var(--color-border)',
                        cursor: 'default',
                        boxShadow: 'none',
                    },
                },
                raw: gap,
                row: [
                    {
                        name: 'Gap',
                        value: distanceToString(gap),
                        props: {
                            style: {
                                alignItems: 'center',
                            },
                        },
                    },
                ],
            };
        },
        formatTrips(trips) {
            return trips.map(operationRecord => {
                return {
                    row: [
                        // { name: 'Kennz.', value: operationRecord.licenseNumber },
                        {
                            name: 'Beginn',
                            value: format(new Date(operationRecord.startAt), 'dd.MM.yyyy HH:mm'),
                        },
                        {
                            name: 'Ende',
                            value: format(new Date(operationRecord.endAt), 'dd.MM.yyyy HH:mm'),
                        },
                        {
                            name: 'Gesamt KM',
                            value: distanceToString(operationRecord.totalDistance),
                        },
                        {
                            name: 'Besetzt KM',
                            value: distanceToString(operationRecord.hiredDistance),
                        },
                        {
                            name: 'Eur/KM',
                            value: priceToEuroString(
                                operationRecord.finalTotalAmount /
                                    Math.max(operationRecord.totalDistance / 1000, 1),
                            ),
                        },
                        {
                            name: 'Umsatz (€)',
                            value: priceToEuroString(operationRecord.finalTotalAmount),
                        },
                    ],
                };
            });
        },
    },
    mounted() {
        console.log('mounted', this.operationRecords);
    },
};
</script>

<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    > * {
        min-width: 1000px;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    .Button {
        display: block;
        margin: 30px auto !important;
    }
}
</style>
